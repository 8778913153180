import React from 'react';
import NavButtonGroup from "../../components/NavButtonGroup";

const NavBarMicrofrontend = () => (
  <div className="react-component">
    <NavButtonGroup/>
  </div>
);

export default NavBarMicrofrontend;
